import { useAccountStore } from '@/vantool/stores/account'
import api from '@/vantool/utils/axios'
import createLogger from '@/vantool/utils/logger'

const logger = createLogger('WorkOrders')

export default {
  get(id) {
    return api
      .get(`/internal/vantool/work-orders/${id}/`)
      .then(({ data }) => {
        const indx = this.state.workorders.findIndex((w) => w.id === id)
        console.log('GET workorder', data.id)
        if (indx === -1) {
          this.state.workorders.push(data)
        } else {
          this.state.workorders[indx] = data
        }
        return data
      })
      .catch((err) => {
        console.log('CAUGHT', err)
        if (err.statusCode === 403) {
          this.router.push({ name: 'login' })
        }
      })
  },

  async create(params) {
    let response
    try {
      response = await api.post('/internal/vantool/work-orders/', {
        ...params,
        van: useAccountStore().account?.current_van?.id
      })
    } catch (error) {
      error.message = `Failed creating workorder: ${error.message}`
      throw error
    }

    const workorder = response.data
    console.log(`WORKORDER ${workorder.id} created with success`, workorder)

    return workorder
  },

  sendReadySMS(workOrderId, pickupDeadline) {
    const payload = {
      pickup_deadline: pickupDeadline
    }
    return api
      .post(`/internal/vantool/b2c/work-orders/${workOrderId}/send-ready-sms`, payload)
      .then(() => {
        console.log('SEND READY SMS success:', workOrderId)
        return this.actions.workorders.get(workOrderId)
      })
  },

  startPayment(workOrderId, paymentType) {
    api.get(`/payments/send_payment/${workOrderId}/${paymentType}`).then(({ data }) => {
      window.location.href = data.payment_url
    })
  },

  async cancelPayment(workOrderId) {
    let response
    try {
      response = await api.post(`/payments/cancel_payment/${workOrderId}`)
    } catch (error) {
      error.message = `Failed cancelling payment of work order ${workOrderId}: ${error.message}`
      logger.error(error.message)
      throw error
    }

    this.state.workorders = this.state.workorders.map((workOrder) => {
      if (workOrder.id === workOrderId) return response.data
      return workOrder
    })

    logger.debug(`Successfully cancelled payment of work order ${workOrderId}`)
  },

  async otherPayment(workOrderId, paymentType) {
    let response
    try {
      response = await api.post(`/payments/other_payment/${workOrderId}`, {
        type: paymentType
      })
    } catch (error) {
      error.message = `Failed creating payment of type ${paymentType} for work order ${workOrderId}: ${error.message}`
      logger.error(error.message)
      throw error
    }

    this.state.workorders = this.state.workorders.map((workOrder) => {
      if (workOrder.id === workOrderId) return response.data
      return workOrder
    })
    logger.debug(
      `Successfully created payment of type ${paymentType} for work order ${workOrderId}`
    )
  },

  sendReceiptEmail(workOrderId) {
    return api.post(`/b2c/work-orders/${workOrderId}/send-receipt-email`).then(() => {
      console.log('SEND RECEIPT EMAIL success:', workOrderId)
      return this.actions.workorders.get(workOrderId)
    })
  },

  getPaymentTypes() {
    return api.get('/payments/types/').then(({ data }) => {
      console.log('PAYMENTTYPES: types found with success', data)
      this.state.paymenttypes = data
      return data
    })
  }
}
