import api from '@/vantool/utils/axios'

export default {
  all() {
    return api.get('/internal/vantool/products').then(({ data }) => {
      console.log('PRODUCTS: found', data)
      this.state.products = data
      return data
    })
  },

  async fetchCategories() {
    return api.get('/internal/vantool/product-categories').then((response) => {
      this.state.productCategories = response.data
      return this.state.productCategories
    })
  }
}
