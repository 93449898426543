import { reactive } from 'vue'
import * as actions from './actions'

const cleanParams = (params) => {
  return params !== null
    ? Object.fromEntries(Object.entries(params).filter(([_, v]) => v != null))
    : null
}

/** Allow actions to access `store` through `this` */
function bindActionsToStore(actionsObject, store) {
  for (const actionModuleName in actionsObject) {
    const actionFunctions = actionsObject[actionModuleName]
    for (const actionName in actionFunctions) {
      actionFunctions[actionName] = actionFunctions[actionName].bind(store)
    }
  }
  return actionsObject
}

let Store = null

export default function createStore() {
  if (Store === null) {
    Store = {
      API_ROOT: '/api/',

      getUrl: function (path, params = null) {
        const queryParams = `?${new URLSearchParams(cleanParams(params))}`
        return `${this.API_ROOT}${path}${params ? queryParams : ''}`
      },

      state: reactive({
        bike_models: [],
        statuses: [],
        customerprofiles: [],
        countries: null,
        users: [],
        discountcodes: [],
        yeplers: [],
        bikes: [],
        vans: [],
        workorders: [],
        questionnaires: [],
        products: [],
        workordertasktypes: [],
        workorderremarktypes: [],
        publicWorkordertasktypes: [],
        publicWorkorderremarktypes: [],
        bookings: [],
        account: null,
        businessWorkorders: { count: 0, results: [], prev: null, next: null },
        businessWorkordersByBike: {},
        enableAccessibility: false,
        damageReports: [],
        damageTypes: [],
        scannedQrCode: {}
      }),

      actions: {},

      getters: {
        getTaskType(taskTypeId) {
          return this.state.publicWorkordertasktypes.filter((t) => t.id === taskTypeId)
        }
      },
      router: null,
      Vue: null
    }

    Store.actions = bindActionsToStore(actions, Store)
  }
  return Store
}
